<template>
  <div class="long-sentence-editor">
    <RadioGroup v-model="type" type="button" size="large">
      <Radio :label="1">精讲</Radio>
      <Radio :label="2">习题</Radio>
    </RadioGroup>
    <div v-show="type == 1" class="flex">
      <EditorSider
        :activeIndex.sync="jds.cur"
        :len="jds.items.length"
        :isEmptyFn="jds.isEmptyFn"
        @clickAdd="jds.addItem(currentContext)"
        @clickRemove="jds.removeItem($event)"
      />
      <div
        v-for="(jd, index) in jds.items"
        :key="jd.id"
        v-show="index === jds.cur"
        class="flex1 m-l-sm"
      >
        <h3>在此输入待解析的文本内容（注：粘贴前，请先清空当前内容，防止解析错误）</h3>
        <quilljs-editor
          class="editor"
          :content.sync="jd.editorContent"
          @change="jds.onEditorChange(jd, currentContext, $event)"
        />
        <h3>1、来源</h3>
        <Input type="text" placeholder="输入来源" v-model="jd.form.source" title />
        <h3>2、原句</h3>
        <Input type="textarea" placeholder="" :rows="4" v-model="jd.form.origin" title />
        <h3>3、带角标原句</h3>
        <quilljs-editor class="editor" :content.sync="jd.form.formatOrigin" />
        <h3>4、主干</h3>
        <quilljs-editor class="editor" :content.sync="jd.form.trunk" />
        <h3>
          5、成分（若解析失败，可以手动编辑）
          <div class="vui-r">
            <template v-if="jd.editing">
              <span class="text-primary hover vui-mt5" title="保存" @click="save(jd, index, jds.items)">保存</span>
              <span class="text-warning hover vui-mt5" title="保存" @click="cancel(jd)">取消</span>
            </template>
            <span v-else class="text-success hover vui-mt5" title="编辑" @click="startEdit(jd)">
              <Icon type="ios-create-outline" />编辑
            </span>
          </div>
        </h3>
        <table class="component">
          <thead>
            <tr>
              <th>成分</th>
              <th>结构名称</th>
              <th v-if="jd.editing">
                <span
                  v-if="jd.editing"
                  class="text-success hover vui-mt5"
                  title="添加行"
                  @click="add(-1, jd.form.component)"
                >
                  <Icon type="md-add" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index2) in jd.form.component" :key="index2">
              <td>
                <template v-if="jd.editing">
                  <Input v-model="item.wordTxt" title />
                </template>
                <template v-else>{{ item.wordTxt }}</template>
              </td>
              <td>
                <template v-if="jd.editing">
                  <Input v-model="item.name" title />
                </template>
                <template v-else>{{ item.name }}</template>
              </td>
              <td v-if="jd.editing">
                <span
                  v-if="jd.editing"
                  class="text-success hover vui-mt5"
                  title="添加行"
                  @click="add(index2, jd.form.component)"
                >
                  <Icon type="md-add" />
                </span>
                <span
                  v-if="jd.editing"
                  class="text-error hover vui-mt5"
                  title="删除该行"
                  @click="remove(index2, jd.form.component)"
                >
                  <Icon type="md-remove" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>6、译文</h3>
        <Input type="textarea" placeholder="输入译文" :rows="4" v-model="jd.form.translation" title />
        <h3>7、分析</h3>
        <Input type="textarea" placeholder="输入分析" :rows="4" v-model="jd.form.analysis" title />
        <h3>8、难点</h3>
        <Input type="textarea" placeholder="输入难点" :rows="4" v-model="jd.form.difficulty" title />
        <h3>9、单词</h3>
        <Input type="textarea" placeholder="输入单词" :rows="4" v-model="jd.form.wordAnalysis" title />
      </div>
    </div>
    <div v-show="type == 2" class="flex">
      <EditorSider
        :activeIndex.sync="xts.cur"
        :len="xts.items.length"
        :isEmptyFn="xts.isEmptyFn"
        @clickAdd="xts.addItem(currentContext)"
        @clickRemove="xts.removeItem($event)"
      />

      <div
        v-for="(xt, index) in xts.items"
        :key="xt.id"
        v-show="index === xts.cur"
        class="flex1 m-l-sm"
      >
        <h3>在此输入待解析的文本内容（注：粘贴前，请先清空当前内容，防止解析错误）</h3>
        <quilljs-editor
          class="editor"
          :content="xt.editorContent"
          @change="xts.onEditorChange(xt, currentContext, $event)"
        />
        <h3>1、原句</h3>
        <Input type="textarea" placeholder="输入来源" :rows="4" v-model="xt.form.origin" title />
        <h3>
          2、成分（若解析失败，可以手动编辑）
          <div class="vui-r">
            <template v-if="xt.editing">
              <span class="text-primary hover vui-mt5" title="保存" @click="save(xt, index, xts.items)">保存</span>
              <span class="text-warning hover vui-mt5" title="保存" @click="cancel(xt)">取消</span>
            </template>
            <span v-else class="text-success hover vui-mt5" title="编辑" @click="startEdit(xt)">
              <Icon type="ios-create-outline" />编辑
            </span>
          </div>
        </h3>
        <table class="component">
          <thead>
            <tr>
              <th>成分</th>
              <th>结构名称</th>
              <th v-if="xt.editing">
                <span
                  v-if="xt.editing"
                  class="text-success hover vui-mt5"
                  title="添加行"
                  @click="add(-1, xt.form.component)"
                >
                  <Icon type="md-add" />
                </span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index2) in xt.form.component" :key="index2">
              <td>
                <template v-if="xt.editing">
                  <Input v-model="item.wordTxt" title />
                </template>
                <template v-else>{{ item.wordTxt }}</template>
              </td>
              <td>
                <template v-if="xt.editing">
                  <Input v-model="item.name" title />
                </template>
                <template v-else>{{ item.name }}</template>
              </td>
              <td v-if="xt.editing">
                <span
                  v-if="xt.editing"
                  class="text-success hover vui-mt5"
                  title="添加行"
                  @click="add(index2, jd.form.component)"
                >
                  <Icon type="md-add" />
                </span>
                <span
                  v-if="xt.editing"
                  class="text-error hover vui-mt5"
                  title="删除该行"
                  @click="remove( index2, xt.form.component)"
                >
                  <Icon type="md-remove" />
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <h3>3、译文</h3>
        <Input type="textarea" placeholder="输入译文" :rows="4" v-model="xt.form.translation" title />
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import QuilljsEditor from '../lib/quilljs/QuilljsEditor.vue';

export default {
  components: {
    QuilljsEditor
  },
  data() {
    return {
      type: 1,
      // 精读
      jds: {
        cur: 0,
        items: [],
        addItem() {
          const item = {
            id: this.items.length + 1,
            editing: false,
            editorContent: '',
            form: {
              source: '',
              origin: '',
              formatOrigin: '',
              trunk: '',
              translation: '',
              analysis: '',
              difficulty: '',
              wordAnalysis: '',
              component: []
            }
          };
          this.items.push(item);
          this.cur = this.items.length - 1;
        },
        removeItem(index) {
          this.items.splice(index, 1);
          if (this.cur >= this.items.length) {
            this.cur = this.items.length - 1;
          }
        },
        isEmptyFn(/**index */) {
          // let form = this.items[index].form;

          return true;
        },
        onEditorChange(context, currentContext, content) {
          const res = currentContext.analyze(content);
          context.form.origin = res.origin;
          context.form.formatOrigin = res.formatOrigin;
          context.form.trunk = res.trunk;
          context.form.component = Array.from(Array(Math.max(res.wordAnalysis.length, res.grammars.length))).map(
            (x, index) => ({
              wordTxt: res.wordAnalysis.length > index ? res.wordAnalysis[index] : null,
              name: res.grammars.length > index ? res.grammars[index] : null
            })
          );
          // context.form.component.wordAnalysis = res.wordAnalysis;
          // context.form.component.grammars = res.grammars;
          context.form.component.len = Math.max(res.wordAnalysis.length, res.grammars.length);
        }
      },
      // 习题
      xts: {
        cur: 0,
        items: [],
        addItem() {
          const item = {
            id: this.items.length + 1,
            editing: false,
            editorContent: '',
            form: {
              origin: '',
              translation: '',
              component: []
            }
          };
          this.items.push(item);
          this.cur = this.items.length - 1;
        },
        removeItem(index) {
          this.items.splice(index, 1);
          if (this.cur >= this.items.length) {
            this.cur = this.items.length - 1;
          }
        },
        isEmptyFn() {
          return true;
        },
        onEditorChange(context, currentContext, content) {
          const res = currentContext.analyze(content);
          context.form.origin = res.origin;
          // context.form.formatOrigin = res.formatOrigin;
          // context.form.trunk = res.trunk;
          context.form.component = Array.from(Array(Math.max(res.wordAnalysis.length, res.grammars.length))).map(
            (x, index) => ({
              wordTxt: res.wordAnalysis.length > index ? res.wordAnalysis[index] : null,
              name: res.grammars.length > index ? res.grammars[index] : null
            })
          );
          // context.form.component.wordAnalysis = res.wordAnalysis;
          // context.form.component.grammars = res.grammars;
          context.form.component.len = Math.max(res.wordAnalysis.length, res.grammars.length);
        }
      }
    };
  },
  computed: {
    currentContext() {
      return this;
    }
  },
  methods: {
    // 解析输入
    analyze(htmlStr) {
      // 转换成 element
      const el = document.createElement('div');
      el.innerHTML = htmlStr;

      let originArr = [];
      let formatOrigin = '';

      const elClone = el.cloneNode(true);

      // 带角标原句
      Array.from(elClone.querySelectorAll('p')).forEach(cur => {
        // 替换多个空格为1个
        cur.innerHTML = cur.innerHTML.replace(/(&nbsp;)+/g, '&nbsp;');
        const $us = cur.querySelectorAll(':scope > u');
        const $sl = cur.querySelectorAll(':scope > span').length;
        if ((!$us.length && !$sl.length) || $sl.length) {
          // 删除语法
          cur.remove();
        } else {
          const curArr = Array.from($us).map(x => x.innerText);
          if (curArr.length) {
            // 译文
            let senText;
            let lastText;
            cur.querySelectorAll(':scope > sup').forEach($sup => {
              // 获取角标前一个语句，取最后一个单词
              const $sen = $sup.previousSibling;
              senText = $sen.innerText.split(' ');
              if (!$sen.previousSibling && senText.length === 1) {
                lastText = senText.pop().trim();
              } else {
                lastText = ' ' + senText.pop();
              }
              $sen.remove();

              let $lastText = document.createElement('span');
              $lastText.innerHTML = lastText;
              $lastText.style.color = 'rgb(0, 176, 240)';
              $lastText = cur.insertBefore($lastText, $sup);

              const $span = document.createElement('span');
              $span.innerHTML = senText.join(' ');
              cur.insertBefore($span, $lastText);

              $sen.innerText = senText.join(' ');
            });

            // 去除下划线
            cur.querySelectorAll(':scope > u').forEach($u => {
              const $s = document.createElement('span');
              $s.innerHTML = $u.innerText;
              cur.replaceChild($s, $u);
            });
          }
          // 将 p 标签改为 span
          const $sp = document.createElement('span');
          $sp.innerHTML = cur.innerHTML;
          elClone.replaceChild($sp, cur);
        }
      });

      // 单词，针对换行引起的问题，解决方案为：将每一行的最后一个单词与下一行的第一个单词连接
      const wordAnalysis = Array.from(el.querySelectorAll('p'))
        .reduce((prev, cur) => {
          const curArr = Array.from(cur.querySelectorAll(':scope > u')).map(x => x.innerText.trim());

          if (curArr.length) {
            if (prev.length) {
              const lastText = prev.pop().trim();
              curArr[0] = lastText + ' ' + curArr[0].trim();
            }

            // 带角标
            const supArr = Array.from(cur.querySelectorAll(':scope > u, sup')).map(x => x.innerText);

            if (supArr.length) {
              formatOrigin += supArr.join('');
            }

            // 删除角标
            Array.prototype.forEach.call(cur.querySelectorAll('sup'), function(node) {
              if (node.nextSibling && node.nextSibling.innerHTML) {
                node.parentNode.replaceChild(document.createTextNode('\u00A0'), node);
                // node.nextSibling.innerHTML = '&nbsp;' + node.nextSibling.innerHTML;
              } else {
                node.parentNode.removeChild(node);
              }
            });

            originArr.push(...cur.innerText.trim().split(/\s+/));

            return [...prev, ...curArr];
          }

          return prev;
        }, [])
        .filter(cur => {
          // 过滤非英文字符，点或者空格
          return /[0-9a-z]/i.test(cur);
        });

      // 语法
      let grammars = Array.from(el.querySelectorAll('p > span'))
                          .filter(x => {
                            console.log(x.innerText);
                            return /.*[\u4e00-\u9fa5]+.*$/.test(x.innerText);
                          }).map(x => x.innerText);

      grammars = grammars.reduce((prev, cur) => {
        return [...prev, ...cur.split(/(\s+)/).filter(e => e.trim().length > 0)];
      }, []);

      // 获得语法列表后，重新渲染主干，并设置颜色
      const grammarMainKeyArr = ['主语', '谓语', '宾语'];
      const grammarMains = grammars
        .map((x, index) => ({ item: x, index }))
        .filter(x => {
          return grammarMainKeyArr.some(y=>x.item.indexOf(y)> -1);
        })
        .map(x => x.index);
      let _idx = 0;
      Array.from(el.querySelectorAll('p')).forEach(cur => {
        // 替换多个空格为1个
        cur.innerHTML = cur.innerHTML.replace(/(&nbsp;)+/g, '&nbsp;');
        const $us = cur.querySelectorAll(':scope > u');
        const $sl = cur.querySelectorAll(':scope > span').length;
        if ((!$us.length && !$sl.length) || $sl.length) {
          // 删除语法
          cur.remove();
        } else {
          Array.from($us).forEach((cur2, index) => {
            if (index !== 0) {
              _idx += 1;
            }
            const $s = document.createElement('span');
            $s.innerHTML = cur2.innerText;
            if (grammarMains.indexOf(_idx) > -1) {
              $s.style.color = 'orangered';
            }
            // 去除下划线
            cur.replaceChild($s, cur2);
          });
          // 将 p 标签改为 span
          const $sp = document.createElement('span');
          $sp.innerHTML = cur.innerHTML;
          el.replaceChild($sp, cur);
        }
      });

      //console.log('trunk：' + el.innerHTML);

      return {
        origin: originArr.join(' '),
        formatOrigin: elClone.innerHTML,
        trunk: el.innerHTML,
        wordAnalysis, // 成分
        grammars
      };
    },
    startEdit(item) {
      item.ingredient_bak = _.cloneDeep(item.form.component);
      item.editing = true;
    },
    save(item, index, items) {
      // 去除空值
      item.form.component = item.form.component.filter(x=>x.name || x.wordTxt);
      item.editing = false;
      //this.$set(items, index, item);
    },
    cancel(item) {
      item.form.component = item.ingredient_bak;
      item.ingredient_bak = null;
      item.editing = false;
    },
    add(index, parent) {
      parent.splice(index + 1, 0, {
        wordTxt: null,
        name: null
      });
    },
    remove(index, parent) {
      parent.splice(index, 1);
    },
    // 验证
    verify() {
      for (const [index, x] of this.jds.items.entries()) {
        for (const x2 of Object.keys(x.form)) {
          const o = x.form[x2];
          if (x2 !== 'component') {
            if (!o.length) {
              this.$Message.warning(`精读的第${index + 1}个界面的填写不完整，请确认`);
              return false;
            }
          }
        }
      }
      for (const [index, x] of this.xts.items.entries()) {
        for (const x2 of Object.keys(x.form)) {
          const o = x.form[x2];
          if (x2 !== 'component') {
            if (!o.length) {
              this.$Message.warning(`习题的第${index + 1}个界面的填写不完整，请确认`);
              return false;
            }
          }
        }
      }
      return true;
    },
    // 解析输入，生成用于上传的数据
    getData() {
      return {
        preciseExplainList: this.jds.items.map(x => ({
          moduleType: 'preciseExplain',
          divList: Object.keys(x.form).map(x2 => ({
            chapterCode: x2, // 见枚举截图
            componentList: x2 === 'component' ? x.form[x2] : [],
            // 内容
            content: x2 === 'component' ? null : x.form[x2]
          }))
        })),
        exercisesList: this.xts.items.map(x => ({
          moduleType: 'exercises',
          divList: Object.keys(x.form).map(x2 => ({
            chapterCode: x2, // 见枚举截图
            componentList: x2 === 'component' ? x.form[x2] : [],
            // 内容
            content: x2 === 'component' ? null : x.form[x2]
          }))
        }))
      };
    },
    // 重置表单
    reset() {
      this.jds.items = [];
      this.jds.addItem();
      this.xts.items = [];
      this.xts.addItem();
    }
  },
  beforeMount() {
    if (!this.jds.items.length) {
      this.jds.addItem();
    }
    if (!this.xts.items.length) {
      this.xts.addItem();
    }
  }
};
</script>

<style lang="less" scoped>
.long-sentence-editor {
  position: relative;

  .nums {
    position: absolute;
    left: -5px;
    transform: translate3d(-100%, 0, 0);
    list-style: none;

    .ivu-btn {
      width: 20px;
      height: 24px;
      padding: 0;
      line-height: 24px;
      margin-top: 2px;
    }

    .btn-add {
      vertical-align: middle;
      margin-top: 2px;
      font-size: 18px;
      color: #000;
    }
  }

  h3 {
    font-weight: normal;
    font-size: 14px;
    line-height: 36px;
  }

  .component {
    width: 100%;
    border-collapse: collapse;

    > thead {
      background-color: #eee;
    }

    th,
    td {
      width: 50%;
      padding: 5px;
    }
  }

  .component,
  .component th,
  .component td {
    border: 1px solid #ddd;
  }
}
.flex {
  align-items: start;
}
</style>

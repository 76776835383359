<template>
  <EditorFrame v-if="hb" :hb="hb" ref="frame">
    <div v-if="direct26">精读文章暂时不支持直接到6审的编辑</div>
    <LongSentenceEditor v-else :hb="hb" ref="longSentenceEditor" />

    <template v-slot:buttons>
      <Button
        v-if="true"
        class="center-block-btn"
        type="primary"
        @click="submitFn(10)"
        :disabled="isDisable"
        >确认无误，提交</Button
      >
      <div v-if="!hb.parentId" class="next" @click="toDraft()" type="primary">
        保存至我的草稿，下次继续
      </div>
    </template>
    <template v-slot:right-side>
      <Tabs :value="'name2'">
        <TabPane label="编辑状态" name="name2">
          <EditStatus ref="editStatus" :hb="hb" />
        </TabPane>
      </Tabs>
    </template>
  </EditorFrame>
  <!-- 编辑 -->
  <div v-else></div>
</template>

<script >
import LongSentenceEditor from "./LongSentenceEditor";
import { getHbTaskView, saveAsDraft } from "../api/hbService";
import { longSentence_start } from "../api/hbService";

export default {
  components:{LongSentenceEditor},
  data() {
    return {
      hb: null,
      isDisable: false, // 开关
      direct26: false,
    };
  },
  mounted() {
    this.initTaskView();
    this.direct26 = /submit_to_final/.test(this.$store.getters.getRoles);
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.initTaskView();
  },
  methods: {
    //获取任务详情
    initTaskView() {
      this.currentIndex = 0;
      let {
        bizId,
        parentId,
        contentLevel,
        rewriteId,
        parentProcessId,
      } = this.$route.query;
      let contentType = "enLongSentence";

      if (bizId) {
        // 正常的草稿
        getHbTaskView({ bizId, contentType }).then((res) => {
          res.content.taskName = 0;
          res.content.audioMap = undefined;
          let hb = res.content;
          hb.customReadModule = hb.customReadModule || false;
          hb.readModuleList = hb.readModuleList || [];
          hb.intensiveReadConfigList = hb.intensiveReadConfigList || [];
          this.hb = hb;
        });
      } else if (parentId || rewriteId) {
        // parentId 来自回收站，rewriteId 来自 已发布的重写
        getHbTaskView({ bizId: parentId || rewriteId, contentType }).then(
          (res) => {
            let hb = res.content;
            hb.audioMap = undefined;
            hb.taskName = 0;
            hb.id = "";
            hb.taskId = "";
            hb.processId = "";
            hb.parentProcessId = parentProcessId;
            hb.parentId = parentId || rewriteId;
            hb.customReadModule = hb.customReadModule || false;
            hb.readModuleList = hb.readModuleList || [];
            hb.intensiveReadConfigList = hb.intensiveReadConfigList || [];
            this.hb = hb;
          }
        );
      } else {
        this.hb = {
          title: "",
          mdContentList: [""],
          backGroundList: [],
          contentTitle: [{ content: "", key: "", url: "", name: "" }],
          sort: null,
          attributeList: [
            {
              author: "",
              dynasty: "",
              contentSource: "",
              backgroudUrl: "",
              backgroudKey: "",
              musicKey: "",
              musicUrl: "",
              contentDivList: [],
            },
          ],
          subheading: "",
          contentType,
          contentLevel,
          picMap: {}, //无需关心空指针
          id: "",
          status: 0,
          tagMap: { 0: [] },
          audioTag: [[]],
          imageTag: [[]],
          audioLines: [],
          ageType: "",
          category: "",
          secondCategory: "",
          taskName: 0,
          comment: "",
          customReadModule: false,
          readModuleList: [],
          intensiveReadConfigList: [],
        };
      }
    },
    // pass：0-正常提交，8-跨级提交到配图,10提交到6审
    async submitFn(pass = 0) {
      let message = this.$refs.frame.validateBaseInfo();
      if (message) {
        this.$Message.warning(message);
        return;
      }
      this.hb.pass = pass;

      let msg;

      if (this.$refs.longSentenceEditor.verify()) {
        Object.assign(this.hb, this.$refs.longSentenceEditor.getData());
        msg = this.$Message.loading({
          content: "提交中....",
          duration: 0,
        });
        this.isDisable = true;
        await longSentence_start(this.hb)
          .then(() => {
            this.$Message.success("提交成功！");
            this.$refs.longSentenceEditor.reset();
            this.$router.back();
          })
          .catch((err) => {
            this.$Message.warning(err);
            throw err;
          })
          .finally(() => {
            msg();
            this.isDisable = false;
          });
      }
    },

    async toDraft(event) {
      let message = this.$refs.frame.validateBaseInfo();
      if (message) {
        this.$Message.warning(message);
        return;
      }
      let msg = this.$Message.loading({
        content: "提交中....",
        duration: 0,
      });
      saveAsDraft(this.hb)
        .then((res) => {
          this.afterSaveSuccess();
          this.$Message.success("已保存到草稿!");
          this.hb.id = res.id;
          this.$router.replace({
            path: this.$route.path,
            query: {
              bizId: res.id,
              contentLevel: this.$route.query.contentLevel,
            },
          });
        })
        .catch((err) => {
          this.$Message.warning("保存草稿失败！");
        })
        .finally(() => {
          msg();
        });
    },
    afterSaveSuccess() {
      if (window.onDBModify) {
        window.onDBModify();
      }
    },
  },
};
</script>

<style scoped>
::v-deep .ivu-tabs {
  width: 600px;
}

.next {
  width: 14em;
  text-align: center;
  margin: 30px auto 0;
  color: #0099ff;
  cursor: pointer;
}

</style>

<template>
  <div class="editor" ref="editor"></div>
</template>

<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

export default {
  name: 'QuilljsEditor',
  props: {
    content: {
      default: '',
      type: String
    }
  },
  data() {
    return {
      editor: null
    };
  },
  watch: {
    content: {
      handler(val) {
        this.$nextTick(() => {
          const delta = this.editor.clipboard.convert(val);
          this.editor.setContents(delta, 'user');
        });
      }
    }
  },
  mounted() {
    this.editor = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: false
      }
    });
    // 监听 change
    this.editor.on('text-change', () => {
      this.$emit('update:content', this.editor.container.firstChild.innerHTML);
      this.$emit('change', this.editor.container.firstChild.innerHTML);
    });
    const delta = this.editor.clipboard.convert(this.content);
    this.editor.setContents(delta, 'user');
  }
};
</script>
